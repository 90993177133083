<template>
  <div v-if="packages !== null">
    <section class="secction-h tour-section-2 mt-5" v-if="packages.length > 0">
      <b-container class="b1-section2-row mb-4">
        <b-row class="px-3 px-lg-0">
          <h3 v-if="localeTitle == 'en'">
            {{ area.enTitle }}
          </h3>
          <h3 v-if="localeTitle == 'cn'">
            {{ area.cnTitle }}
          </h3>
          <h3 v-if="localeTitle == 'hk'">
            {{ area.hkTitle }}
          </h3>
        </b-row>
        <b-row v-if="area.featuredVideoUrl">
          <video
            width="1140"
            height="640px"
            controls
            :autoplay="area.autoplay==='Y'?true:false"
            :muted="area.autoplay==='Y'?true:false"
            :loop="area.loopFlag==='Y'?true:false"
            class="w-100 px-3 px-lg-0"
            v-if="area.featuredVideoUrl"
          >
            <source :src="oss+area.featuredVideoUrl" />
            {{ $t('header.browser_msg') }}
          </video>
        </b-row>
      </b-container>
      <div class="tour-section-2-content">
        <b-container class="b2-section2-row pt-4 pb-4">
          <div class="row mb-3">
            <div class="col-lg-6" v-if="packages[0]">
              <div class="card mb-2">
                <img
                  v-bind:src="oss+packages[0].mainPicture"
                  class="card-img-top"
                  alt="Image"
                />
                <div class="card-body">
                  <h5 class="pt-1">
                    <span
                      v-shave="{ height: 60, character: '...', spaces: false }"
                      >{{ packages[0].shortDescription }}
                    </span>
                  </h5>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 40,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          ({{ packages[0].packageCode }})
                          {{ packages[0].title }}
                        </span>
                      </h6>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right align-bottom pb-1"
                      v-if="packages[0].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[0].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="col-lg-6" v-if="packages[1]">
              <div class="card mb-2">
                <img
                  v-bind:src="oss+packages[1].mainPicture"
                  class="card-img-top"
                  alt="Image"
                />
                <div class="card-body">
                  <h5 class="pt-1">
                    <span
                      v-shave="{ height: 60, character: '...', spaces: false }"
                      >{{ packages[1].shortDescription }}
                    </span>
                  </h5>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 40,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          ({{ packages[1].packageCode }})
                          {{ packages[1].title }}
                        </span>
                      </h6>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right align-bottom pb-1"
                      v-if="packages[1].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[1].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4" v-if="packages[2]">
              <div class="card mb-2">
                <img
                  v-bind:src="oss+packages[2].mainPicture"
                  class="card-img-top"
                  alt="Image"
                />
                <div class="card-body">
                  <h5 class="pt-1">
                    <span
                      v-shave="{ height: 60, character: '...', spaces: false }"
                      >{{ packages[2].shortDescription }}
                    </span>
                  </h5>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 40,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          ({{ packages[2].packageCode }})
                          {{ packages[2].title }}
                        </span>
                      </h6>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right align-bottom pb-1"
                      v-if="packages[2].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[2].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="col-lg-4" v-if="packages[3]">
              <div class="card mb-2">
                <img
                  v-bind:src="oss+packages[3].mainPicture"
                  class="card-img-top"
                  alt="Image"
                />
                <div class="card-body">
                  <h5 class="pt-1">
                    <span
                      v-shave="{ height: 60, character: '...', spaces: false }"
                      >{{ packages[3].shortDescription }}
                    </span>
                  </h5>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 40,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          ({{ packages[3].packageCode }})
                          {{ packages[3].title }}
                        </span>
                      </h6>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right align-bottom pb-1"
                      v-if="packages[3].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[3].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="col-lg-4" v-if="packages[4]">
              <div class="card mb-2">
                <img
                  v-bind:src="oss+packages[4].mainPicture"
                  class="card-img-top"
                  alt="Image"
                />
                <div class="card-body">
                  <h5 class="pt-1">
                    <span
                      v-shave="{ height: 60, character: '...', spaces: false }"
                      >{{ packages[4].shortDescription }}
                    </span>
                  </h5>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <h6 class="purple-text">
                        <span
                          v-shave="{
                            height: 40,
                            character: '...',
                            spaces: false,
                          }"
                        >
                          ({{ packages[4].packageCode }})
                          {{ packages[4].title }}
                        </span>
                      </h6>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right align-bottom pb-1"
                      v-if="packages[4].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[4].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <b-row>
            <b-col class="text-center mt-2" v-if="packages.length > 5">
              <a :href="`#/packages/section2`"
                ><b-button
                  class="view-more"
                  squared
                  variant="outline-secondary"
                  >{{ $t('More') }}</b-button
                ></a
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
  </div>
</template>
<script>
import configUrl from '@/global/config.js'
export default {
  name: 'Section2',
  components: {},
  data() {
    return {
      area: null,
      loading: true,
      errored: false,
      localeTitle: 'en',
      oss:''
    };
  },
  props: {
    area: {
      type: Array,
    },
  },
  computed:{
    packages(){
      return this.area&&this.area.packagesVos||[]
    }
  },
  mounted() {
    this.oss=configUrl.oss_path
    if (localStorage.getItem('lang_frontend') == 'en') {
      this.localeTitle = 'en';
    } else if (localStorage.getItem('lang_frontend') == 'cn') {
      this.localeTitle = 'cn';
    } else {
      this.localeTitle = 'hk';
    }
  },
};
</script>
<style type="text/css">
.tour-section-2-content {
  /*background-image: url(/img/banner_s1.png);*/
  /*background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url(/img/banner_s1.png);*/
  background-color: #0e2b33;
  background-repeat: no-repeat;
  background-size: cover;
}
.b2-section2-row {
  padding-top: 2rem;
  padding-top: 1rem;
}
.b2-section2-row .col-lg-6,
.b2-section2-row .col-lg-4 {
  padding: 0;
}
.b2-section2-row .col-lg-6:nth-child(1) {
  padding-right: 5px;
}
.b2-section2-row .col-lg-6:nth-child(2) {
  padding-left: 5px;
}
.b2-section2-row .col-lg-4:nth-child(1) {
  padding-right: 7px;
}
.b2-section2-row .col-lg-4:nth-child(2) {
  padding-left: 3.5px;
  padding-right: 3.5px;
}
.b2-section2-row .col-lg-4:nth-child(3) {
  padding-left: 7px;
}
.b2-section2-row .col-lg-6 img {
  height: 25rem;
}
.b2-section2-row .col-lg-4 img {
  height: 21rem;
}
.b2-section2-row .card-body {
  height: 7rem;
}
.b2-section2-row .col-lg-6 .card-body h5 {
  height: 3rem;
}
.card-body {
  padding: 1rem;
  align-items: end;
  display: grid;
}
.card-img-top {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.card-body h4,
.card-body h5,
.card-body {
  font-weight: 400;
}
.card {
  border-radius: unset;
}
.card-body .purple-text {
  font-weight: 400;
}
.tour-section-2 button:hover {
  border: 1px solid #f6a604;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.packages !== null)?_c('div',[(_vm.packages.length > 0)?_c('section',{staticClass:"secction-h tour-section-2 mt-5"},[_c('b-container',{staticClass:"b1-section2-row mb-4"},[_c('b-row',{staticClass:"px-3 px-lg-0"},[(_vm.localeTitle == 'en')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.enTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'cn')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.cnTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'hk')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.hkTitle)+" ")]):_vm._e()]),(_vm.area.featuredVideoUrl)?_c('b-row',[(_vm.area.featuredVideoUrl)?_c('video',{staticClass:"w-100 px-3 px-lg-0",attrs:{"width":"1140","height":"640px","controls":"","autoplay":_vm.area.autoplay==='Y'?true:false,"loop":_vm.area.loopFlag==='Y'?true:false},domProps:{"muted":_vm.area.autoplay==='Y'?true:false}},[_c('source',{attrs:{"src":_vm.oss+_vm.area.featuredVideoUrl}}),_vm._v(" "+_vm._s(_vm.$t('header.browser_msg'))+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"tour-section-2-content"},[_c('b-container',{staticClass:"b2-section2-row pt-4 pb-4"},[_c('div',{staticClass:"row mb-3"},[(_vm.packages[0])?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mb-2"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.oss+_vm.packages[0].mainPicture,"alt":"Image"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"pt-1"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({ height: 60, character: '...', spaces: false }),expression:"{ height: 60, character: '...', spaces: false }"}]},[_vm._v(_vm._s(_vm.packages[0].shortDescription)+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 40,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 40,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" ("+_vm._s(_vm.packages[0].packageCode)+") "+_vm._s(_vm.packages[0].title)+" ")])])]),(_vm.packages[0].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[0].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])]):_vm._e(),(_vm.packages[1])?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mb-2"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.oss+_vm.packages[1].mainPicture,"alt":"Image"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"pt-1"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({ height: 60, character: '...', spaces: false }),expression:"{ height: 60, character: '...', spaces: false }"}]},[_vm._v(_vm._s(_vm.packages[1].shortDescription)+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 40,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 40,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" ("+_vm._s(_vm.packages[1].packageCode)+") "+_vm._s(_vm.packages[1].title)+" ")])])]),(_vm.packages[1].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[1].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.packages[2])?_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card mb-2"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.oss+_vm.packages[2].mainPicture,"alt":"Image"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"pt-1"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({ height: 60, character: '...', spaces: false }),expression:"{ height: 60, character: '...', spaces: false }"}]},[_vm._v(_vm._s(_vm.packages[2].shortDescription)+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 40,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 40,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" ("+_vm._s(_vm.packages[2].packageCode)+") "+_vm._s(_vm.packages[2].title)+" ")])])]),(_vm.packages[2].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[2].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])]):_vm._e(),(_vm.packages[3])?_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card mb-2"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.oss+_vm.packages[3].mainPicture,"alt":"Image"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"pt-1"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({ height: 60, character: '...', spaces: false }),expression:"{ height: 60, character: '...', spaces: false }"}]},[_vm._v(_vm._s(_vm.packages[3].shortDescription)+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 40,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 40,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" ("+_vm._s(_vm.packages[3].packageCode)+") "+_vm._s(_vm.packages[3].title)+" ")])])]),(_vm.packages[3].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[3].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])]):_vm._e(),(_vm.packages[4])?_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card mb-2"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.oss+_vm.packages[4].mainPicture,"alt":"Image"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"pt-1"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({ height: 60, character: '...', spaces: false }),expression:"{ height: 60, character: '...', spaces: false }"}]},[_vm._v(_vm._s(_vm.packages[4].shortDescription)+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h6',{staticClass:"purple-text"},[_c('span',{directives:[{name:"shave",rawName:"v-shave",value:({
                          height: 40,
                          character: '...',
                          spaces: false,
                        }),expression:"{\n                          height: 40,\n                          character: '...',\n                          spaces: false,\n                        }"}]},[_vm._v(" ("+_vm._s(_vm.packages[4].packageCode)+") "+_vm._s(_vm.packages[4].title)+" ")])])]),(_vm.packages[4].link == 'Y')?_c('b-col',{staticClass:"text-right align-bottom pb-1",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[4].packageCode}`}},[_c('b-button',{attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('View_details')))])],1)],1):_vm._e()],1)],1)])]):_vm._e()]),_c('b-row',[(_vm.packages.length > 5)?_c('b-col',{staticClass:"text-center mt-2"},[_c('a',{attrs:{"href":`#/packages/section2`}},[_c('b-button',{staticClass:"view-more",attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('More')))])],1)]):_vm._e()],1)],1)],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }